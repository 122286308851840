import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/codebuild/output/src3867633504/src/ccffl-gatsby/src/components/layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Charter`}</h1>
    <p>{`The following document is a general guideline for how the league operates. As rules change this document will be updated accordingly. For any rule question or situtation not documented it is up to the commissioner's sole discretion to make a ruling.`}</p>
    <h2>{`Platform`}</h2>
    <p>{`We are currently using `}<a parentName="p" {...{
        "href": "https://sleeper.app/"
      }}>{`Sleeper.app`}</a>{`. In order to change the platform a vote must pass with 75% approval from the league owners prior to the start of the season. Even with this approval, the commissioner may veto the vote unless someone else is willing to do the legwork to setup the league on the new site.`}</p>
    <p>{`For historical purposes, the previous platforms used were:`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": "left"
          }}>{`League`}</th>
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Seasons`}</th>
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Reasons for Leaving`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><a parentName="td" {...{
              "href": "https://fantasy.nfl.com/"
            }}>{`NFL.com`}</a></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`2011 - 2016`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Bad mobile experience.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><a parentName="td" {...{
              "href": "https://www.fleaflicker.com/"
            }}>{`Fleaflicker.com`}</a></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`2017`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`No native app. Pay to remove ads.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><a parentName="td" {...{
              "href": "https://www.cbssports.com/fantasy/football/"
            }}>{`CBS.com`}</a>{`*`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`2018`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Too much money.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><a parentName="td" {...{
              "href": "https://sleeper.app"
            }}>{`Sleeper.app`}</a></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`2019 - Present`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}></td>
        </tr>
      </tbody>
    </table>
    <p>{`*`}{` The draft for 2019 and 2020 was performed on `}<a parentName="p" {...{
        "href": "https://www.espn.com/fantasy/football/"
      }}>{`ESPN.com`}</a>{` because `}<a parentName="p" {...{
        "href": "https://sleeper.app"
      }}>{`Sleeper.app`}</a>{` did not support Auction Drafts at the time`}</p>
    <h2>{`Teams`}</h2>
    <p>{`There are 12 teams in this league.`}</p>
    <h2>{`Draft`}</h2>
    <p>{`As of 2018 we have switched to an auction draft. Teams received \\$200 to draft all 14 players. If the draft platform we choose allows it, teams are not required to draft all starting positions.`}</p>
    <p>{`Draft order will be determined randomly prior to the draft using some (hopefully silly) format.`}</p>
    <h2>{`Scoring`}</h2>
    <p>{`This league will follow standard fantasy football scoring with fractional points enabled. Ties are allowed in the regular season. The basics are listed below:`}</p>
    <h3>{`Receiving and Rushing`}</h3>
    <ul>
      <li parentName="ul">{`6 points per touchdown`}</li>
      <li parentName="ul">{`1 point per 10 yards`}</li>
      <li parentName="ul">{`1 bonus point for touchdowns longer than 40 yards`}</li>
      <li parentName="ul">{`-1 point per fumble lost`}</li>
    </ul>
    <h3>{`Passing`}</h3>
    <ul>
      <li parentName="ul">{`4 points per touchdown`}</li>
      <li parentName="ul">{`1 point per 25 yards`}</li>
      <li parentName="ul">{`-1 point per fumble lost`}</li>
    </ul>
    <h3>{`Defense & Special Teams`}</h3>
    <ul>
      <li parentName="ul">{`6 points per touchdown`}</li>
      <li parentName="ul">{`1 point per sack`}</li>
      <li parentName="ul">{`1 point per fumble`}</li>
      <li parentName="ul">{`1 point per fumble recovered`}</li>
      <li parentName="ul">{`Yardage and point accumulation scoring will be whatever the platform we use sets as standard`}</li>
    </ul>
    <h3>{`Kicking`}</h3>
    <ul>
      <li parentName="ul">{`3 points <= 30 yards`}</li>
      <li parentName="ul">{`0.1 points for each yard over 30 yards`}</li>
      <li parentName="ul">{`-1 point for missed attempts`}</li>
    </ul>
    <h2>{`Rosters`}</h2>
    <ul>
      <li parentName="ul">{`1 QB`}</li>
      <li parentName="ul">{`2 RBs`}</li>
      <li parentName="ul">{`2 WRs`}</li>
      <li parentName="ul">{`1 TE`}</li>
      <li parentName="ul">{`1 Flex (RB/WR/TE)`}</li>
      <li parentName="ul">{`1 K`}</li>
      <li parentName="ul">{`1 DST`}</li>
      <li parentName="ul">{`5 Bench`}</li>
    </ul>
    <h2>{`Trades`}</h2>
    <p>{`All agreed upon trades may only be vetoed by the commissioner if collusion is overwhelmingly apparent. There will be no voting on trades from other teams.`}</p>
    <p>{`The trade deadline will be set in the 10th week of the regular season.`}</p>
    <h2>{`Waivers`}</h2>
    <p>{`Waivers will be performed auction style. Each team will be given \\$100 in waiver acquistion budget (WAB) after the draft and they must use this throughout the entire season to grab players off of waivers.`}</p>
    <p>{`Waivers will clear Monday - Saturday at 12:00am PST.`}</p>
    <p>{`All players will be free agents on Sundays.`}</p>
    <h2>{`Divisions`}</h2>
    <p>{`There are 4 divisions of 3 teams. Divisions will be determined by best effort placing of teams who have played each other the least.`}</p>
    <h2>{`Scheduling`}</h2>
    <h3>{`Regular Season`}</h3>
    <p>{`The regular season will be 13 weeks long. Division opponents will play eachother twice in the regular season and everyone else once.`}</p>
    <h3>{`Playoffs`}</h3>
    <p>{`The playoffs will be 3 weeks long during weeks 14, 15, 16. Teams will be re-seeded in week 15 so that the highest seeded team plays the lowest seeded team that has advanced to week 15.`}</p>
    <p>{`6 teams will qualify for the playoffs:`}</p>
    <ul>
      <li parentName="ul">{`4 division winners`}</li>
      <li parentName="ul">{`2 wildcard teams`}</li>
    </ul>
    <p>{`The top 2 teams will receive a bye in the first round. These teams are determined using the following tie breakers in the order listed below:`}</p>
    <ol>
      <li parentName="ol">{`Overall record`}</li>
      <li parentName="ol">{`Points For`}</li>
    </ol>
    <h3>{`Board Shorts Bowl`}</h3>
    <p>{`The Board Shorts Bowl will be played on week 14.`}</p>
    <p>{`2 teams will qualify for the Board Shorts Bowl.`}</p>
    <h2>{`Qualifying for Playoffs`}</h2>
    <h3>{`Division Winners`}</h3>
    <p>{`Division winners are determined using the following tie breakers in the order listed below:`}</p>
    <ol>
      <li parentName="ol">{`Overall record`}</li>
      <li parentName="ol">{`Division record`}</li>
      <li parentName="ol">{`Points For`}</li>
      <li parentName="ol">{`Head to head record`}</li>
    </ol>
    <h3>{`Wildcard Teams`}</h3>
    <p>{`Wildcard teams are determined using the following tie breakers in the order listed below:`}</p>
    <ol>
      <li parentName="ol">{`Overall record`}</li>
      <li parentName="ol">{`Points For`}</li>
      <li parentName="ol">{`Head to head record`}</li>
    </ol>
    <h2>{`Qualifying for Board Shorts Bowl`}</h2>
    <p>{`The 2 teams with the worst overall records will qualify for the Board Shorts Bowl.`}</p>
    <p>{`In the event that multiple teams share the same overall record, the team(s) with the least amount of points for will qualify for the Board Shorts Bowl.`}</p>
    <h2>{`Settling Ties in the Playoffs and/or Board Shorts Bowl`}</h2>
    <p>{`In the unlikely event of a tie in the Playoffs or Board Shorts Bowl the higher seeded team will be awarded the win in reflection of a better overall regular season performance.`}</p>
    <h2>{`Board Shorts Bowl Loss Implications`}</h2>
    <p>{`Any manager who loses in the Board Shorts Bowl must wear a pair of Board Shorts made by the Champion at the next season's draft.`}</p>
    <p>{`If the Board Shorts Bowl loser is not present at the draft the Champion must make an effort to mail the Board Shorts in advance.`}</p>
    <p>{`If the Board Shorts do not fit or are deemed unwearable for any reason by the commissioner they may be placed on the surface in front of the Board Shorts Bowl loser instead of being worn.`}</p>
    <h2>{`Financing`}</h2>
    <h3>{`Dues`}</h3>
    <p>{`The league dues are \\$150 per team and will be collected on a best effort basis at the draft. If a team owner is unable or unavailable to pay at draft time, he/she must pay no later than the end of September. Any team that has not paid by the end of September will have their roster locked until they have paid.`}</p>
    <h3>{`Payouts`}</h3>
    <p>{`Any money used to establish the league (platform registration fee for example) will be immediately subtracted from the pot. From what is left, the payouts will be:`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Amount`}</th>
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Recipient`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`\\$20`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Highest Weekly Points (Weeks 7-13)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`\\$150`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Regular Season Points Leader`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`60/40`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Champion/Runner-up split of remaining pot`}</td>
        </tr>
      </tbody>
    </table>
    <h2>{`Unsportsmanlike Conduct`}</h2>
    <h3>{`Failing to set a lineup`}</h3>
    <p>{`If any team does not set a full and legal lineup during any week of the regular season due to negligence that team owner will be subject to a \\$20 fine payable to the pot.`}</p>
    <h3>{`Add/drops after regular Season`}</h3>
    <p>{`Only teams in the playoffs or board shorts bowl may add or drop players after the regular season has ended. Any team eliminated from the playoffs may not longer make any adds or drops.`}</p>
    <h1>{`Change Tracking`}</h1>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Date`}</th>
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Change`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`2020-06-21`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Initial Revision`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`2020-09-02`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Updates on Playoffs, Board Shorts Bowl, Waivers, Penalties`}</td>
        </tr>
      </tbody>
    </table>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      